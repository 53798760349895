/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /* Header init
        -----------------------------------------------------------------------*/
        $("#main-header").mainHeader();

        /* Mobile nav
        -----------------------------------------------------------------------*/
        $("#hamburger").mobileNav();

        /* Slideshow
        -----------------------------------------------------------------------*/
        $("#page-slideshow").slideshow();

        /* Equal height
        -----------------------------------------------------------------------*/
        $('.match').matchHeight({byRow: true});


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'single': {
      init: function() {

        /* Fix image src errors - in case we cant import their images
        -----------------------------------------------------------------------*/
        $(".blog-layout img").fixImgError();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);




/*------------------------------------------------------------------------------------
    Fix image errors
-------------------------------------------------------------------------------------*/
$.fn.fixImgError = function()
{
  var $target = $(this);
  var count = 0;

  if($target.length)
  {
    $target.each(function()
    {
      $(this).error(function()
      {
        var broken_src = $(this).attr('src');

        $(this).attr('data-error',broken_src).hide();
      });
    });
  }
};


/*------------------------------------------------------------------------------------
    Declare FN: Header
-------------------------------------------------------------------------------------*/
$.fn.mainHeader = function()
{
  var $target = $(this),
      $body = $("body"),
      $Window = $(window),
		  $pos = $Window.scrollTop(),
      $height = 140;

  if($target.length)
  {
    //Scrolling fn
    $Window.scroll(function()
    {
      $pos = $Window.scrollTop();

      if($pos>=$height)
      {
        $body.addClass("scrolling");
      } else {
        $body.removeClass("scrolling");
      }
    });
  }

};


/*------------------------------------------------------------------------------------
    Declare FN: Mobile nav
-------------------------------------------------------------------------------------*/
$.fn.mobileNav = function()
{
  var $target = $(this),
      $header = $("#main-header"),
      $mobile_nav = $("#mobile-menu"),
      $mobile_wrap = $mobile_nav.find(".nav");

  //Hamburger
  $target.click(function(e)
	{
		e.preventDefault();

		var $this = $(this);

		if($this.hasClass("active"))
		{
			$this.removeClass("active");
      $header.removeClass("active");
      $mobile_nav.animate({ height:0 });
		}
		else
		{
			$this.addClass("active");
      $header.addClass("active");
      $mobile_nav.animate({ height:$mobile_wrap.height() });
		}

		return false;
	});
};


/*--------------------------------------------------------------
    FN: slideshow
---------------------------------------------------------------*/
$.fn.slideshow = function()
{
  //var slideshow
  var $target = $(this);

  if($target.length)
  {
    var $prev = $target.find(".arrow-prev"),
        $next = $target.find(".arrow-next");

    if($target.find(".item").length > 1)
    {
      //Slideshow init
      $target.slick({
       slidesToShow: 1,
       slidesToScroll: 1,
       slide: ".item",
       autoplay: false,
       autoplaySpeed: 6000,
       speed: 800,
       arrows: true,
       fade: false,
       adaptiveHeight:true,
       prevArrow: $prev,
       nextArrow: $next,
       dots: false
     });

    }
  }
};


})(jQuery); // Fully reference jQuery after this point.
